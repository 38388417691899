import React from 'react'

import Nav from '../../components/navigation/Navigation'
export default function AboutMain() {
    return (
        <div>
            
            <Nav></Nav>
        </div>

        
    )
}
