import React from 'react'
import {Route, Link, Redirect, Switch} from "react-router-dom";
import Zombies from './Zombie';
export default function Client() {
    return (
        <div>
        INFO
        
        </div>
    )
}
