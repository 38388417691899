import React from 'react'
import Nav from '../../components/navigation/Navigation'
export default function Zombie() {
    return (
        <div>
            <Nav></Nav>
            test
        </div>
    )
}
